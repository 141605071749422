import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  Box,
  Button,
  CircularProgress,
  ListItem,
  TextField,
  Typography,
} from '@material-ui/core'
import { ClearOutlined } from '@material-ui/icons'
import { Autocomplete } from '@material-ui/lab'
import match from 'autosuggest-highlight/match'
import parse from 'autosuggest-highlight/parse'
import { kPrimaryLight } from 'configs/styles/muiThemes'
import React, { useRef } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { useDispatch, useSelector } from 'react-redux'
import {
  searchLabOfferings,
  resetCatalogueSearchStatus,
} from 'redux/lab/masterDataSearch/masterDataSearchSlice'

import { RootState } from 'redux/rootReducer'
import { logger } from 'utils/logger'

interface LabOfferingSelectorProps {
  onSelectionChanges?: (selectedValues: R4.IPlanDefinition[]) => void
  doctorListId?: string
  preselectedDefinition?: R4.IPlanDefinition[]
  preSelectAll?: boolean
  disabled: boolean
}

export const MasterDataSelecterMultiple: React.FC<LabOfferingSelectorProps> = ({
  onSelectionChanges,
  doctorListId,
  preselectedDefinition,
  preSelectAll = false,
  disabled,
}: LabOfferingSelectorProps) => {
  const masterDataSearchSlice = useSelector(
    (state: RootState) => state.masterDataSearchSlice
  )
  const dispatch = useDispatch()

  const [value, setValue] = React.useState<R4.IPlanDefinition>()
  const [searchText, setSearchText] = React.useState<string>('')
  const offSet = useRef<number>(1)

  function loadMoreResults(pageNumber: number) {
    dispatch(
      searchLabOfferings(
        pageNumber,
        masterDataSearchSlice.labOfferings ?? [],
        searchText
      )
    )
  }

  //   useEffect(() => {
  //     if (masterDataSearchSlice.labOfferings ?? [].length > 0)
  //       dispatch(resetCatalogueSearchStatus())
  //   }, [dispatch, masterDataSearchSlice.labOfferings])

  return (
    <ErrorBoundary
      fallbackRender={({ error, resetErrorBoundary }) => (
        <div role='alert'>
          <div>Error while fetching provider list</div>
          <pre>{error.message}</pre>
          <Button
            onClick={() => {
              dispatch(
                searchLabOfferings(
                  101,
                  masterDataSearchSlice.labOfferings ?? [],
                  searchText
                )
              )
            }}
          >
            Try again
          </Button>
        </div>
      )}
    >
      <Box width='100%'>
        <Box width='100%'>
          <Autocomplete
            id='search_tests_multiple'
            ListboxProps={{
              onScroll: (event: React.SyntheticEvent) => {
                const listboxNode = event.currentTarget
                if (
                  listboxNode.scrollTop + listboxNode.clientHeight ===
                  listboxNode.scrollHeight
                ) {
                  if (masterDataSearchSlice.total) {
                    if (masterDataSearchSlice.total > 100) {
                      offSet.current += 100
                      loadMoreResults(offSet.current)
                    }
                  }
                }
              },
              style: {
                maxHeight: 500,
              },
            }}
            onOpen={() => {}}
            onClose={() => {
              //   dispatch(resetCatalogueSearchStatus())
            }}
            style={{
              borderRadius: '4px',
            }}
            disableClearable
            autoComplete
            includeInputInList
            filterSelectedOptions
            fullWidth
            multiple
            limitTags={4}
            size='small'
            disabled={disabled}
            value={preselectedDefinition}
            getOptionLabel={(option) => (option ? option.title ?? '' : '')}
            getOptionSelected={(option, value1) =>
              option && value1 ? option.name === value1.name : false
            }
            options={masterDataSearchSlice.labOfferings ?? []}
            loading={masterDataSearchSlice.searching}
            onChange={(e, changedValue, reason) => {
              logger.info(changedValue)
              if (onSelectionChanges) {
                dispatch(resetCatalogueSearchStatus())
                onSelectionChanges(changedValue)
              }
            }}
            onBlur={(e) => {
              console.log(e)
            }}
            ChipProps={{
              deleteIcon: (
                <ClearOutlined
                  style={{
                    height: '15px',
                    color: 'white',
                  }}
                />
              ),
              style: {
                backgroundColor: kPrimaryLight,
                borderRadius: '4px',
              },
            }}
            renderOption={(option, { inputValue }) => {
              const matches = match(option.title ?? '', inputValue)
              const parts = parse(option.title ?? '', matches)
              return (
                <ListItem id={`lab_test_opt_multiple_${option.id}`}>
                  <div>
                    {parts.map((part, index) => (
                      <span
                        key={part.text}
                        style={{
                          backgroundColor: part.highlight
                            ? kPrimaryLight
                            : undefined,
                          fontWeight: part.highlight ? 700 : 400,
                        }}
                      >
                        {part.text}
                      </span>
                    ))}
                  </div>
                </ListItem>
              )
            }}
            filterOptions={(x) => x}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder='Type at least 3 characters'
                variant='outlined'
                id='search_plan_test'
                onChange={(e) => {
                  setSearchText(e.target.value)
                  if (e.target.value.length > 2) {
                    dispatch(
                      searchLabOfferings(
                        1,
                        masterDataSearchSlice.labOfferings ?? [],
                        e.target.value
                      )
                    )
                    dispatch(resetCatalogueSearchStatus())
                  }
                }}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {masterDataSearchSlice.searching ? (
                        <CircularProgress color='inherit' size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
          />
        </Box>

        {masterDataSearchSlice.error && (
          <Typography>
            {' '}
            {masterDataSearchSlice.errorMessage ?? 'Error while searching'}
          </Typography>
        )}
        {masterDataSearchSlice.noResultsAvailable && (
          <Typography> No Results available</Typography>
        )}
      </Box>
    </ErrorBoundary>
  )
}
